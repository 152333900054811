import React, { useEffect, useRef } from 'react';
import { Toast } from "primereact/toast";
import { observer } from "mobx-react-lite";
import { useDataContext } from "../../data/DataContext";

const ToastHandler = () => {
    const toast = useRef<Toast>();

    const { notificationsStore } = useDataContext().stores;

    const showSuccess = (summary: string, detail?: string) => {
        toast.current?.show({ severity: 'success', summary, detail, life: 3000 });
    };

    const showInfo = (summary: string, detail?: string) => {
        toast.current?.show({ severity: 'info', summary, detail, life: 3000 });
    };

    const showWarn = (summary: string, detail?: string) => {
        toast.current?.show({ severity: 'warn', summary, detail, life: 3000 });
    };

    const showError = (summary: string, detail?: string) => {
        toast.current?.show({ severity: 'error', summary, detail, life: 3000 });
    };

    useEffect(() => {
        const note = notificationsStore.getLastElement;
        if (note !== null && !note.isShowed) {
            notificationsStore.markAsShowed(note.id);
            switch (note.importance) {
                case "ERROR":
                    showError(note.title, note.message);
                    return;
                case "WARNING":
                    showWarn(note.title, note.message);
                    return;
                case "INFO":
                    showInfo(note.title, note.message);
                    return;
                default:
                    showSuccess(note.title, note.message);
            }
        }
    }, [ notificationsStore, notificationsStore.unreadNotifications.length ]);

    return (
        // @ts-ignore
        <Toast ref={toast} position="bottom-right"/>
    );
};

export default observer(ToastHandler);
