import React, { FC, useEffect } from 'react';
import cn from "classnames";
import styles from "./TopBar.module.scss";
import Tippy from "@tippyjs/react";
import { useI18nLocaleFormat } from "../../../hooks/useI18nLocaleFormat";
import { useDataContext } from "../../../data/DataContext";
import { useIsBrowser } from "../../../hooks/useIsBrowser";
import { themeName } from "../../../data/app/AppState";
import { observer } from "mobx-react-lite";

interface DarkLightBarProps {
}

const replaceLink = (linkElement, href) => {
    const id = linkElement.getAttribute('id');
    const cloneLinkElement = linkElement.cloneNode(true);

    cloneLinkElement.setAttribute('href', href);
    cloneLinkElement.setAttribute('id', id + '-clone');

    linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

    cloneLinkElement.addEventListener('load', () => {
        linkElement.remove();
        cloneLinkElement.setAttribute('id', id);
    });
}

const DarkLightBar: FC<DarkLightBarProps> = () => {
    const { localeAs } = useI18nLocaleFormat();
    const { appStateStore } = useDataContext().stores;
    const { appState } = useDataContext().stores.appStateStore;
    const isBrowser = useIsBrowser();
    const { themeBrightness, disableTips } = appState;

    useEffect(() => {
        const themeElement = document.getElementById('theme-link');
        const themeHref = `/themes/${themeName[themeBrightness]}/theme.css`;
        replaceLink(themeElement, themeHref);
    }, [ themeBrightness ]);

    const clickHandler = () => {
        if (themeBrightness === "dark") {
            appStateStore.updateAppState({
                ...appState,
                themeBrightness: "light"
            });
        } else {
            appStateStore.updateAppState({
                ...appState,
                themeBrightness: "dark"
            });
        }
    };

    if (!isBrowser) {
        return null;
    }

    return (
        <li>
            <Tippy
                content={
                    themeBrightness === "dark" ?
                        localeAs("general.brightness.light") : localeAs("general.brightness.dark")
                }
                disabled={disableTips}
            >
                <button
                    className={cn("p-link", styles.layout__topbar_button)}
                    onClick={clickHandler}
                >
                    {
                        themeBrightness === "dark" &&
                        <i className="pi pi-sun"/>
                    }
                    {
                        themeBrightness === "light" &&
                        <i className="pi pi-moon"/>
                    }
                </button>
            </Tippy>
        </li>
    );
};

export default observer(DarkLightBar);
