import React, { FC } from 'react';
import TopBar from "./TopBar/TopBar";
import styles from "./Layout.module.scss";
import Meta from "../Meta/Meta";
import ToastHandler from "../Notifications/ToastHandler";
import { DraftScope } from "../../data/srm/models/draft.models";

interface LayoutProps {
    title?: string;
    scope?: DraftScope;
}

const Layout: FC<LayoutProps> = (props) => {
    return (
        <div>
            <Meta title={props.title}
                  scope={props.scope}/>
            <ToastHandler/>
            <TopBar/>
            <div className={styles.layout__container}>
                <div className={styles.layout__main}>
                    {props.children}
                </div>
            </div>
        </div>
    );
};

export default Layout;
