import React, { FC } from "react";

import { LocaleMessagesType, useI18nLocaleFormat } from "../../hooks/useI18nLocaleFormat";


export interface LocaleMessageAsProps {
    id: LocaleMessagesType;
    params?: { [key: string]: any };
}

export const LocaleMessageAs: FC<LocaleMessageAsProps> = (props): any => {
    const {
        id,
        params,
    } = props;

    if (!id) {
        return null;
    }

    const { localeAs } = useI18nLocaleFormat();

    let message = localeAs(id);

    if (params) {
        for (const [key, value] of Object.entries(params)) {
            message = message.replace(`%${key}%`, value.toString());
        }
    }

    return message;
}
