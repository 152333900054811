import { CfpEvent } from "./models/events.models";
import { DraftScope } from "./models/draft.models";

const MEETUP_PROJECTS = [ "EXTEVENT", "MEETUPS" ];

export const cfpIsFuture = (cfpEvent?: CfpEvent) => cfpEvent?.status === 'FUTURE';
export const cfpIsClosed = (cfpEvent?: CfpEvent) => cfpEvent?.status === 'CLOSED';
export const cfpIsRestricted = (cfpEvent?: CfpEvent) => cfpEvent?.status === 'RESTRICTED';
export const cfpIsPublicOrPrivate = (cfpEvent?: CfpEvent) => cfpEvent?.status === 'PUBLIC' || cfpEvent?.status === 'PRIVATE';
export const cfpIsPrivate = (cfpEvent?: CfpEvent) => cfpEvent?.status === 'PRIVATE';
export const cfpIsPublic = (cfpEvent?: CfpEvent) => cfpEvent?.status === 'PUBLIC';
export const getEventVersionTitle = (event?: CfpEvent): string => event ? event?.project.toLocaleUpperCase() + " " + event?.version : undefined;

export const filterMeetupEvents = (events: CfpEvent[], scope: DraftScope) => {
    if (([ "MEETUP" ] as DraftScope[]).includes(scope)) {
        return events.filter(e => MEETUP_PROJECTS.includes(e.project))
    } else if (([ "REGULAR", "PARTNER" ] as DraftScope[]).includes(scope)) {
        return events.filter(e => !MEETUP_PROJECTS.includes(e.project));
    }
}
