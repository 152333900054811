import React from 'react';
import cn from "classnames";
import styles from "./TopBar.module.scss";
import { LocaleMessageAs } from "../../LocaleMessage/LocaleMessageAs";
import { useRouter } from "next/router";
import { useI18nLocaleFormat } from "../../../hooks/useI18nLocaleFormat";
import Tippy from "@tippyjs/react";
import Link from 'next/link';

const LanguageBar = ({ disableTips }) => {
    const router = useRouter();
    const { localeAs } = useI18nLocaleFormat();

    return (
        <li>
            {
                router.locale === 'ru'
                    ? (
                        <Link href={router.asPath} locale={'en'} scroll={false}>
                            <button
                                className={cn("p-link", styles.layout__topbar_button, styles.layout__topbar_displayButton)}>
                                <a>
                                    <Tippy
                                        content={localeAs('general.locale.select.beauty.EN')}
                                        disabled={disableTips}
                                    >
                                        <div className={cn(styles.icon)}>
                                            <LocaleMessageAs id={'general.locale.select.EN'}/>
                                        </div>
                                    </Tippy>
                                </a>
                            </button>
                        </Link>
                    )
                    : (
                        <Link href={router.asPath} locale={'ru'} scroll={false}>
                            <button
                                className={cn("p-link", styles.layout__topbar_button, styles.layout__topbar_displayButton)}>
                                <a>
                                    <Tippy
                                        content={localeAs('general.locale.select.beauty.RU')}
                                        disabled={disableTips}
                                    >
                                        <div className={cn(styles.icon)}>
                                            <LocaleMessageAs id={'general.locale.select.RU'}/>
                                        </div>
                                    </Tippy>
                                </a>
                            </button>
                        </Link>
                    )
            }
        </li>
    );
};

export default LanguageBar;
