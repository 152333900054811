import React, { useRef } from 'react';
import { Avatar } from "primereact/avatar";
import styles from "./TopBar.module.scss";
import { useDataContext } from "../../../data/DataContext";
import { Menu } from "primereact/menu";
import { publicEndpointLogin, publicEndpointOAuth } from "../../../data/auth/auth.api.service";
import { MenuItem } from "primereact/menuitem";
import { getLKUrl } from "../../../../environment";
import { useIsBrowser } from "../../../hooks/useIsBrowser";
import { useI18nLocaleFormat } from "../../../hooks/useI18nLocaleFormat";
import { observer } from "mobx-react-lite";
import cn from "classnames";

const Profile = () => {
    const { authStore } = useDataContext()?.stores;
    const { authService } = useDataContext()?.services;
    const { localeAs } = useI18nLocaleFormat();

    const { isLogged, picture, isOnlineAdmin, isGlobalAdmin, isCoordinator } = authStore;

    const loggedMenu = useRef(null);

    const isBrowser = useIsBrowser();

    if (!isBrowser) {
        return null;
    }

    const loggedMenuItems: MenuItem[] = [
        { label: localeAs("auth.profile"), icon: 'pi pi-user', url: getLKUrl() },
        {
            label: localeAs("auth.logout"), icon: 'pi pi-sign-out', command: () => {
                authService.logout().finally(() => window.location.reload());
            }
        }
    ];

    const nonAuthMenuItems: MenuItem[] = [
        { label: 'Login', icon: 'pi pi-sign-in', url: publicEndpointLogin(window.location.href) },
        { label: 'Google login', icon: 'pi pi-google', url: publicEndpointOAuth('google', window.location.href) },
        // { label: 'Yandex login', icon: 'pi pi-sign-out' }
    ];

    if (isLogged === undefined) {
        return null;
    }

    const renderAvatar = () => {
        if (!isLogged) {
            return (
                <Avatar
                    className={cn("p-link", styles.layout__topbar_button, styles.layout__topbar_displayButton)}
                    icon="pi pi-user"
                    shape="circle"
                    onClick={(event) => loggedMenu.current.toggle(event)}
                />
            )
        }
        if (isLogged && picture) {
            return (
                <Avatar
                    className={cn("p-link", styles.layout__topbar_button, styles.layout__topbar_displayButton,
                        {
                            [styles.layout__topbar_profile_logo_admin]: isOnlineAdmin || isGlobalAdmin,
                            [styles.layout__topbar_profile_logo_coordinator]: isCoordinator
                        })
                    }
                    image={authStore.picture}
                    shape="circle"
                    onClick={(event) => loggedMenu.current.toggle(event)}
                />
            )
        }
        if (isLogged && !picture) {
            return (
                <Avatar
                    className={cn("p-link", styles.layout__topbar_button, styles.layout__topbar_displayButton,
                        { [styles.layout__topbar_profile_logo_admin]: isOnlineAdmin || isGlobalAdmin })}
                    label={(authStore.displayName || "User")[0]}
                    shape="circle"
                    onClick={(event) => loggedMenu.current.toggle(event)}
                />
            )
        }
    };

    return (
        <>
            <li>
                {
                    renderAvatar()
                }
            </li>
            <Menu model={authStore.isLogged ? loggedMenuItems : nonAuthMenuItems} popup ref={loggedMenu}/>
        </>
    );
};

export default observer(Profile);
